<template>
<div>
  <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
      <vs-card>
        <h2 class="card-title d-flex">{{$t('DOCUMENTS.HEADER.TITEL')}}
          <div class="ml-auto text-right">
            <vs-button  @click="LoadData()" size="small" color="primary" type="filled" icon="refresh"></vs-button>
          </div>
        </h2>
        <p class="card-subtitle">
          <span>{{$t('DOCUMENTS.HEADER.TEXT')}}</span>
        </p>
      </vs-card>
    </vs-col>
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
      <vs-card >
            <div id="documents_table" class="vs-con-loading__container" >
              <vs-table
                search
                :data="documents"
                :noDataText="$t('DOCUMENTS.NO_DATA')"
                stripe
                max-items="25" 
                pagination
                hoverFlat
                :multiple="selectMultiple"
                v-model="selectedDocuments">

                <template slot="header">
                  <h3>
                    {{$t('DOCUMENTS.TABLE.HEADER')}}
                  </h3>
                </template>
                <template slot="thead">             
                  <vs-th sort-key="name">
                    {{$t('DOCUMENTS.TABLE.COL1')}}
                  </vs-th>
                  <vs-th sort-key="type">
                    {{$t('DOCUMENTS.TABLE.COL2')}}
                  </vs-th>
                  <vs-th sort-key="creator_name">
                    {{$t('DOCUMENTS.TABLE.COL3')}}
                  </vs-th>
                  <vs-th sort-key="created">
                    {{$t('DOCUMENTS.TABLE.COL4')}}
                  </vs-th>        
                  <vs-th sort-key="status">
                    {{$t('DOCUMENTS.TABLE.COL5')}}
                  </vs-th>    
                  <vs-th></vs-th>                                                                                                                                             
                </template>

                <template slot-scope="{data}">
                  <vs-tr  :data="tr" :key="indextr" v-for="(tr, indextr) in data" >                    
                    <vs-td :data="tr.name">
                      {{tr.name}}
                    </vs-td>  
                    <vs-td :data="tr.type">
                     {{GetDokumentTypeText(tr.type)}}
                    </vs-td> 
                    <vs-td :data="tr.creator_name">
                      {{tr.creator_name}}
                    </vs-td> 
                    <vs-td :data="tr.created">
                      {{DateToString(tr.created,false)}}
                    </vs-td> 
                    <vs-td :data="tr.status">
                      {{GetDokumentStatusText(tr.status)}}
                    </vs-td>                         
                    <vs-td>
                      <vs-button  @click="DownloadPdf(tr.id)" :id="'btn_downloadPdf_'+tr.id" class="ml-2" size="small" color="primary" type="filled" icon="get_app"></vs-button> 
                      <vs-button  @click="OpenConfirmDelete(tr.id,tr.name)" class="ml-2" size="small" color="primary" type="filled" icon="delete"></vs-button>                        
                    </vs-td>                    
                                                                                                                                                                                              
                  </vs-tr>
                </template>
              </vs-table>
            </div>   
        <div class="d-flex mt-2">
          <vs-checkbox v-model="selectMultiple">{{$t('DOCUMENTS.CBX.MULTIPLE')}}</vs-checkbox>
        </div>
        <div class="d-flex mt-2">
          <vs-button :disabled="!selectMultiple" color="primary" type="border" icon="delete" @click="OpenConfirmDeleteSelected()">{{$t('COM.DELETE')}}</vs-button>
        </div>        
        </vs-card>
      </vs-col>
  </vs-row>

</div>
</template>

<script>

import helperMethods from '../helper/staticFuncHelper';
import downloadMethods from '../helper/staticDownloadFuncHelper';
import enumHelper from '../helper/enumHelper';

export default {
  name: "Documents",
  components: {
  },
    data: function (){
      return {
      documents:[],
      selectedDocuments:[],
      selectMultiple:false
      };
  },
  mounted () {
      this.LoadData();
    },
  computed: 
  { 
    getTableData() {
      var data = [];
      if(this.$store.state.document.documents.data != null)
      {
        data = this.$store.state.document.documents.data;
      }
      return data;
    },
    loadingTableData () {
      var loading = false;
      if(typeof(this.$store.state.document.documents.status) != 'undefined')
        loading = this.$store.state.document.documents.status.loading;
      return loading;
    }

  },
  watch: {
      getTableData(value) {
          this.documents = value;
      },
      loadingTableData(value)
      {
        if(value)
        {
          this.$store.dispatch('alertqueue/showLoader', {'id':'#documents_table'});
        }
        else
        {
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#documents_table'});
        }
      },
      selectMultiple(){

        this.selectedDocuments = [];
      
      },
    },
  methods: {    
      ...helperMethods,
      ...downloadMethods,
      ...enumHelper,
      LoadData: function ()
      {
        this.$store.dispatch('document/getDocuments',{ root: true },{ root: true }); 
      },
      DownloadPdf(id)
      {
          this.$store.dispatch('alertqueue/showLoader', {'id':'#btn_downloadPdf_'+id, 'scale':0.4});
          
        this.$store.dispatch('document/downloadDocument', id)
        .then(response => {
            this.$store.dispatch('alertqueue/hideLoader', {'id':'#btn_downloadPdf_'+id});
            this.DownloadWithFileSaver(response);
          }
        );
      },
      deleteSelectedDocuments(){
      this.selectedDocuments.forEach(document=>{
        this.$store.dispatch('document/deleteDocument', document.id)
      });
      this.LoadData();
      this.selectMultiple = false;
    },
      OpenConfirmDeleteSelected() {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.DELETE'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('DOCUMENTS.QUESTION.DELETE_SELECTED'),
          accept: this.deleteSelectedDocuments
        });
      
      },    
      OpenConfirmDelete(id,bez) {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.DELETE'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('DOCUMENTS.QUESTION.DELETE').replace("*1*",bez),
          accept: this.SendDeleteRequest,
          parameters:{"id": id,"bez": bez}
        });
      
      },
      SendDeleteRequest: function(parameters)
      {
        this.$store.dispatch('document/deleteDocument', parameters.id)
        .then(response => {
          if(response === true)
          {
            this.LoadData();
              this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('DOCUMENTS.SUCCESS.DELETE').replace("*1*",parameters.bez)});  
          }
          else
          {
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':this.$t('DOCUMENTS.WARNING.DELETE').replace("*1*",parameters.bez)}); 
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('DOCUMENTS.ERROR.DELETE').replace("*1*",error)});  
        });
      }
  }
};

</script>